import React from 'react';
import Layout from '../components/Layout.jsx';

import StoreSearch from '../components/StoreSearch.jsx';

export default ({ data }) => {
  return (
    <Layout title="Find">
      <StoreSearch />
    </Layout>
  )
}
