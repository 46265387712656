import React from 'react';
import css from './StoreDetails.module.css';

function formatOpenHours(str) {
  if (str) {
    const date = new Date();
    const today = date.getDay();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return str.split(',')
      .map(day => day.replace(/\d:/, ''))
      .map(day => day.match(/\d{2}:\d{2}/g))
      .map((day, i) => ({
        day: days[i],
        open: day[0],
        close: day[1]
      }))
      .map((days, i) => (
        <div key={days.day} className={[css.open, (today === i) ? css.today : null].join(' ')}>
          {days.day} <span>{days.open} - {days.close}</span>
        </div>
      ));
  }

  return ''
}

export default (props) =>
  <div className={(props.Name) ? css.active : css.store }>
    { (props) ?
      <>
        <div className={css.header}>
          <button className={css.close} onClick={() => props.setStore(null)}>X</button>
          <div className={css.name}>{ props.Name }</div>
          <address className={css.address}>
            {props.Street}, {props.Town}, {props.Postcode}
          </address>
        </div>
        <ul className={css.details}>
          <li>Phone: {(props.Telephone1) ? props.Telephone1 : <i>Unavailable</i>}</li>
          <li>Opening Hours: {formatOpenHours(props.Hours)}</li>
          <li>Distance: {parseFloat(props.Distance).toFixed(1)} miles</li>
        </ul>
      </>
    : null }
  </div>
