import React from 'react';
import axios from 'axios';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import css from './StoreSearch.module.css';
import StoreDetails from './StoreDetails.jsx';
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

const fascias = [
  { label: 'JD Sports', key: 'JD' },
  { label: 'Scotts', key: 'SC' },
  { label: 'Blacks', key: 'BL' },
  { label: 'Millets', key: 'ML' },
  { label: 'Ultimate Outdoors', key: 'UO' },
  { label: 'Tessuti', key: 'TE' }
];

const clientID = 'gme-jdsportsfashionplc';

const GMap = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    ref={(map) => (map && props.bounds) ? map.fitBounds(props.bounds) : null}
    defaultZoom={8}
    defaultCenter={{ lat: 53.480, lng: -2.242 }}
  >
    <MarkerClusterer
      averageCenter
      enableRetinaIcons
      gridSize={60}
    >
    {
      props.stores.map((store, key) => (
        <Marker
          key={key}
          position={{ lat: store.Latitude, lng: store.Longitude }}
          onClick={() => props.setStore(store)}
        />
      ))
    }
    </MarkerClusterer>
  </GoogleMap>
))

class StoreSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fascia: 'JD',
      postcode: '',
      storeDetails: null,
      stores: [],
      bounds: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    axios
      .get('/api/stores/getneareststoresbypostcode', {
        params: {
          fasciacode: this.state.fascia,
          postcode: this.state.postcode,
          distance: 30
        },
      })
      .then(res => {
        const stores = res.data.Stores;
        this.setState({ stores })
        return stores;
      })
      .then(stores => {
        const LatLngBounds = new window.google.maps.LatLngBounds();

        stores.map(store =>
          LatLngBounds.extend(
            new window.google.maps.LatLng(store.Latitude, store.Longitude))
          );

        this.setState({ bounds: LatLngBounds });
      })
      .catch((err) => console.error(err.toString()));

    event.preventDefault();
  }

  setStore(storeDetails) {
    this.setState({ storeDetails: null });

    if (storeDetails !== null) {
      setTimeout(() => {
        this.setState({ storeDetails });
      }, 250);
    }
  }

  render() {
    return (
      <div className={css.container}>
        <form onSubmit={this.handleSubmit} className={css.form}>
          <h3>1_ CHOOSE A FASCIA:</h3>
          <ul className={css.fascias}>
            {
              fascias.map(fascia => (
                <li key={fascia.key} className={css.fasciaItem}>
                  <input
                    id={fascia.key}
                    className={css.input}
                    name="fascia"
                    type="radio"
                    value={fascia.key}
                    defaultChecked={fascia.key === 'JD' ? 'checked' : null}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor={fascia.key} className={css.label}>
                    { fascia.label }
                  </label>
                </li>
              ))
            }
          </ul>

          <h3>2_ ENTER POST CODE:</h3>
          <div className={css.postcode}>
            <input type="text" name="postcode" value={this.state.postcode} onChange={this.handleInputChange} className={css.textInput} required />
            <input type="submit" value="SEARCH" className={css.submit}/>
          </div>

          <StoreDetails
            {...this.state.storeDetails}
            setStore={this.setStore.bind(this)}
          />
        </form>


        <GMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?client=${clientID}&v=quarterly&callback=initMap`}
          loadingElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          containerElement={<div className={css.gmap} />}
          stores={this.state.stores}
          bounds={this.state.bounds}
          setStore={this.setStore.bind(this)}
        />
      </div>
    );
  }
}

export default StoreSearch;
